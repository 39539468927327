/* Footer Container */
footer {
    background-color: #3bc7da;
    color: white;
    padding: 50px 0;
  }
  
  /* Ready for Next Project Section */
  .ready-section {
    background-color: #3a7bd5; /* Blue shade */
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .ready-section h2 {
    margin-bottom: 10px;
  }
  
  .ready-section button {
    background-color: #fff;
    color: #3a7bd5;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .ready-section button:hover {
    background-color: #2874a6;
    color: #fff;
  }
  
  /* Footer Links */
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-links div {
    flex: 1;
    margin: 10px 0;
  }
  
  .footer-links h6 {
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .footer-links a {
    display: block;
    color: #ccc;
    margin-bottom: 5px;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .footer-links a:hover {
    color: white;
  }
  
  /* Social Media Icons */
  .social-media {
    text-align: center;
    margin-top: 30px;
  }
  
  .social-media a {
    color: white;
    margin: 0 10px;
    font-size: 1.2rem;
    transition: color 0.3s ease;
  }
  
  .social-media a:hover {
    color: #3a7bd5;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .footer-links {
      flex-direction: column;
    }
  
    .footer-links div {
      margin: 20px 0;
    }
  
    .ready-section {
      padding: 15px;
    }
  
    .ready-section h2 {
      font-size: 1.5rem;
    }
  
    .ready-section button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }