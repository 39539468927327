
body, html, .App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.banner {
  display: flex;
  
  justify-content: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  background-image: url('./../../images/frontpage.jpg');
  text-align: center;
  color: white;
  position: relative;
}

.highlight {
  color: #ffd700;
}

.main-content {
  padding: 20px;
}

.contact-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

@media (max-width: 768px) {
  .banner {
    height: 50vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    background-attachment: scroll;
  }

  h1 {
    font-size: 1.3rem;
  }

  .main-content {
    padding: 10px;
  }

  .contact-section {
    padding: 10px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .banner {
    height: 30vh;
    background-repeat: no-repeat;
    background-size: 100%;

  }

   h1 {
    font-size: 0.9rem;
  }

  .contact-section p {
    font-size: 0.7rem;
  }
}
