.about-container {
  padding: 40px;
  text-align: justify;
  background-color: #f9f9f9;
}

h2 {
  font-size: clamp(24px, 5vw, 36px); /* Responsive font size */
  font-weight: bold;
  color: #003366;
}

.section-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: flex-start;
  margin-top: 20px;
  gap: 10px; /* Add spacing between buttons */
}

.section-buttons button {
  margin-right: 20px;
  padding: 10px 20px;
  font-size: clamp(14px, 2.5vw, 18px); /* Responsive font size */
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.section-buttons button:hover,
.section-buttons button.active {
  border-bottom: 2px solid #D92130;
  color: #D92130;
}

.about-content {
  display: flex;
  flex-direction: column; 
  gap: 20px; 
}

.grid-item {
  width: 100%;
}

.text-column {
  flex: 1;
  padding-right: 20px;
}

.stats {
  display: flex;
  flex-wrap: wrap; /* Ensure stats wrap on smaller screens */
  gap: 40px;
  margin-bottom: 20px;
}

.stat-item h2 {
  font-size: clamp(24px, 5vw, 36px);
  color: #0056b3;
}

.stat-item p {
  font-size: clamp(14px, 2.5vw, 18px);
  color: #666;
}

.description h3 {
  font-size: clamp(18px, 4vw, 24px);
  color: #003366;
  margin-bottom: 10px;
}

.description p {
  font-size: clamp(14px, 2.5vw, 16px);
  color: #555;
  line-height: 1.6;
}

.image-column {
  flex: 1;
  display: flex;
  justify-content: center;
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.content-box:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.box-image {
  width: 100%;
}

.box-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.content-box:hover .box-image img {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .grid-item {
    flex-direction: column;
  }

  .text-column {
    padding-right: 0;
  }

  .section-buttons {
    justify-content: center; 
  }
}


@media (max-width: 480px) {
  .about-container {
    padding: 20px 40px;
    text-align: justify;
  }

  h2 {
    font-size: 24px;
  }

  .stats {
    flex-direction: column;
    gap: 20px;
  }

  .content-box {
    padding: 15px;
  }

  .section-buttons button {
    font-size: 14px;
    padding: 8px 15px;
  }
}
